<phar-editor-template [controlType]="FormElementsEnum.NumericRatingScale">
  <phar-question-editor-label general [disabled]="labelsDisabled()" />
  <form response [formGroup]="form">
    <div class="row gx-0">
      <div class="d-flex flex-column col-6 pe-1">
        <mat-label class="required-field">Min</mat-label>
        <mat-form-field class="mat-input-small w-100" subscriptSizing="dynamic">
          <input formControlName="min" matInput placeholder="Min" type="number" />
          <mat-error>
            @if (form.get('min').hasError('min')) {
              Min value can't be negative.
            } @else if (form.get('min').hasError('max')) {
              Value max is 20.
            } @else if (form.get('min').hasError('required')) {
              Min is <b>required</b>.
            }
          </mat-error>
        </mat-form-field>
      </div>

      <div class="d-flex flex-column col-6 ps-1">
        <mat-label class="required-field">Max</mat-label>
        <mat-form-field class="mat-input-small w-100" subscriptSizing="dynamic">
          <input formControlName="max" matInput placeholder="Max" type="number" />
          <mat-error>
            @if (form.get('max').hasError('min')) {
              Max value can't be negative.
            } @else if (form.get('max').hasError('max')) {
              Value max is 20.
            } @else if (form.get('max').hasError('required')) {
              Max is <b>required</b>.
            }
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    @if (form.controls.min.valid && form.controls.max.valid && form.hasError('minMax')) {
      <mat-error> <b>Min</b> must be less than <b>Max</b> </mat-error>
    }

    <div class="d-flex flex-column">
      <mat-label>Display</mat-label>
      <mat-form-field>
        <mat-select formControlName="labelInterval">
          @for (option of labelDisplayOptions; track $index) {
            <mat-option
              [value]="option.value"
              [disabled]="option.value === LabelPosition.MinMaxAndMiddle && form.controls.max.value <= 2">
              <span>{{ option.label }}</span>
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <div formArrayName="labels">
      @for (labelControl of getLabelControls(); let i = $index; track i) {
        <div>
          @if (form.get('labelInterval')?.value === LabelPosition.MinAndMaxOnly) {
            <mat-label class="required-field">{{ labelsMinMax[i] }} </mat-label>
          }

          @if (form.get('labelInterval')?.value === LabelPosition.MinMaxAndMiddle) {
            <mat-label class="required-field">{{ labelsMinMidMax[i] }} </mat-label>
          }
          <mat-form-field class="remove-will-change w-100">
            <!--<phar-html-input
              [formControl]="labelControl"
              placeholder="Label"
              [maxlength]="labelMaxLength"
              [pharMaxLengthTooltip]="labelMaxLength"
            >
            </phar-html-input>
            <mat-hint class="hint-focus-visible">
              Entered characters {{ form.get('labels').get(i + '').value.length }}/{{ labelMaxLength }}
            </mat-hint>-->

            <input
              matInput
              placeholder="Label"
              pharInputTrim
              [formControl]="labelControl"
              [maxlength]="labelMaxLength"
              [pharMaxLengthTooltip]="labelMaxLength" />

            <mat-error> Label is required </mat-error>

            <!--<mat-error>
              Entered characters {{ form.get('labels').get(i + '').value.length }}/{{ labelMaxLength }}
            </mat-error>-->
          </mat-form-field>
        </div>
      }
      @if (form.controls.labels.hasError('uniqueLabels')) {
        <mat-error> Labels must be <b>unique.</b> </mat-error>
      }
    </div>
  </form>
</phar-editor-template>
