import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  addProject,
  addProjectMatrixItemInList,
  bulkUpdateProjectMatrixItemsStatuses,
  checkCurrentProjectForPendingChanges,
  createProject,
  createProjectMatrixItem,
  deleteProject,
  deleteStudyAdmin,
  getProjectMatrixItems,
  getProjectStatusHistory,
  getRecordForms,
  getStudyAdminList,
  loadProjectList,
  loadProjectMatrixItemsCommentsState,
  pendingCreateProject,
  populateCurrentProject,
  populateCurrentProjectSnapshot,
  populateProjectMatrixItems,
  populateProjectMatrixItemsCommentsState,
  populateProjectStatusHistory,
  populateProjectStatusInList,
  populateRecordForms,
  populateStudyAdminList,
  publishAmendedProject,
  publishProject,
  refreshCurrentProject,
  removeProject,
  removeStudyAdminFromList,
  searchProjectById,
  successCreateProject,
  syncStudies,
  updateEntitiesStatuses,
  updateProject,
  updateProjectField,
  updateProjectFields,
  updateProjectList,
  updateProjectMatrixItem,
  updateProjectMatrixItemInList,
  updateProjectStatus,
} from './project.actions';

import { AppState, getState } from '../../store/models/app.state';
import { Store } from '@ngrx/store';
import { ProjectService } from '../project.service';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { ProjectModel } from '../project.model';
import { addLoader, errorPopup, globalLoading, messagePopup, removeLoader } from '../../store/actions/ui.actions';
import { isEqual, omit } from 'lodash-es';
import { IProjectStatus } from '../project-status.interface';
import { Statuses } from '../../shared/models/statuses.enum';
import { IStudyMatrixItem } from '../../study-matrix/study-matrix-editor/study-matrix-item.interface';

@Injectable()
export class ProjectEffect {
  actions = inject(Actions);
  projectService = inject(ProjectService);
  store = inject(Store<AppState>);

  searchProjectById = createEffect(() => {
    return this.actions.pipe(
      ofType(searchProjectById),
      tap(action => {
        if (action.addLoader) {
          this.store.dispatch(addLoader(action));
        }
      }),
      mergeMap(action =>
        this.projectService.searchById(action.id).pipe(
          mergeMap((res: ProjectModel) => [
            populateCurrentProject({ project: res }),
            populateCurrentProjectSnapshot({ project: res }),
            removeLoader(action),
            globalLoading(false),
          ]),
          catchError(() => {
            return [removeLoader(action), globalLoading(false)];
          }),
        ),
      ),
    );
  });

  createProject = createEffect(() => {
    return this.actions.pipe(
      ofType(createProject),
      mergeMap(({ project }) =>
        this.projectService.create(project).pipe(
          mergeMap((res: ProjectModel) => [
            addProject({ project: res }),
            pendingCreateProject({ pending: false }),
            populateCurrentProject({ project: res }),
            populateCurrentProjectSnapshot({ project: res }),
            successCreateProject({ success: true }),
            globalLoading(false),
            messagePopup({ message: 'Study created successfully' }),
          ]),
          catchError(() => {
            return [globalLoading(false), errorPopup({ error: 'There is a problem with creating this Study' })];
          }),
        ),
      ),
    );
  });

  updateProject = createEffect(() => {
    return this.actions.pipe(
      ofType<ReturnType<typeof updateProject>>(updateProject),
      mergeMap(({ project }) =>
        this.projectService.update(project).pipe(
          mergeMap(res => [
            populateCurrentProject({ project: res }),
            populateCurrentProjectSnapshot({ project: res }),
            pendingCreateProject({ pending: false }),
            successCreateProject({ success: true }),
            messagePopup({ message: 'Study updated' }),
            globalLoading(false),
          ]),
          catchError(() => [
            globalLoading(false),
            successCreateProject({ success: false }),
            pendingCreateProject({ pending: false }),
          ]),
        ),
      ),
    );
  });

  updateProjectStatus = createEffect(() => {
    return this.actions.pipe(
      ofType<ReturnType<typeof updateProjectStatus>>(updateProjectStatus),
      mergeMap(({ projectId, newStatus }) =>
        this.projectService.updateProjectStatus(projectId, newStatus).pipe(
          mergeMap((status: IProjectStatus) => {
            return [
              updateProjectField({ field: 'status', value: status }),
              populateProjectStatusInList({ status }),
              getProjectStatusHistory({ projectId }),
              pendingCreateProject({ pending: false }),
              successCreateProject({ success: true }),
              messagePopup({ message: 'Study status updated' }),
              globalLoading(false),
            ];
          }),
          catchError(() => [
            globalLoading(false),
            successCreateProject({ success: false }),
            pendingCreateProject({ pending: false }),
            errorPopup({ error: 'Unable to update Study status' }),
          ]),
        ),
      ),
    );
  });

  getProjectStatusHistory = createEffect(() => {
    return this.actions.pipe(
      ofType<ReturnType<typeof getProjectStatusHistory>>(getProjectStatusHistory),
      tap(action => {
        if (action.addLoader) {
          this.store.dispatch(addLoader(action));
        }
      }),
      mergeMap(action =>
        this.projectService.getProjectAllStatuses(action.projectId).pipe(
          mergeMap((statuses: IProjectStatus[]) => [populateProjectStatusHistory({ statuses }), removeLoader(action)]),
          catchError(() => [removeLoader(action)]),
        ),
      ),
    );
  });

  loadProjectList = createEffect(() => {
    return this.actions.pipe(
      ofType(loadProjectList),
      mergeMap(() =>
        this.projectService.getProjectList().pipe(
          mergeMap(res => [updateProjectList({ projectList: res }), globalLoading(false)]),
          catchError(() => [globalLoading(false)]),
        ),
      ),
    );
  });

  deleteProject = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteProject),
      mergeMap(({ id }) =>
        this.projectService.deleteProject(id).pipe(
          mergeMap(() => [removeProject({ id }), messagePopup({ message: 'Successfully delete a Study' })]),
          catchError(() => [errorPopup({ error: 'There is a problem with deleting this Study' })]),
        ),
      ),
    );
  });
  getStudyAdmins = createEffect(() => {
    return this.actions.pipe(
      ofType(getStudyAdminList),
      mergeMap(({ studyId }) =>
        this.projectService.getStudyAdmins(studyId).pipe(
          mergeMap(res => [populateStudyAdminList({ admins: res })]),
          catchError(() => []),
        ),
      ),
    );
  });

  deleteStudyAdmin = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteStudyAdmin),
      mergeMap(({ id }) =>
        this.projectService.deleteStudyAdmin(id).pipe(
          mergeMap(() => [
            messagePopup({ message: 'Successfully delete a Study admin' }),
            removeStudyAdminFromList({ id: id }),
          ]),
          catchError(() => []),
        ),
      ),
    );
  });

  getRecordForms = createEffect(() => {
    return this.actions.pipe(
      ofType(getRecordForms),
      mergeMap(({ datasetId, projectId }) =>
        this.projectService.getRecordFormsPerProject(projectId, datasetId).pipe(
          mergeMap(res => [populateRecordForms({ recordForms: res })]),
          catchError(() => []),
        ),
      ),
    );
  });

  refreshCurrentProject = createEffect(() => {
    return this.actions.pipe(
      ofType(refreshCurrentProject),
      mergeMap(() => [
        searchProjectById({
          id: getState(this.store).project.current.project.id,
        }),
      ]),
      catchError(() => []),
    );
  });

  updateProjectField = createEffect(() => {
    return this.actions.pipe(
      ofType(updateProjectField, updateProjectFields, successCreateProject, populateCurrentProjectSnapshot),
      mergeMap(() => {
        const current = omit(getState(this.store).project.current.project, ['userLastNameField', 'userFirstNameField']);
        const snapshot = omit(getState(this.store).project.current.projectSnapshot, [
          'userLastNameField',
          'userFirstNameField',
        ]);
        return [
          checkCurrentProjectForPendingChanges({
            hasChanges: !isEqual(current, snapshot),
          }),
        ];
      }),
      catchError(() => []),
    );
  });

  loadProjectMatrixItemsCommentsCounter = createEffect(() => {
    return this.actions.pipe(
      ofType(loadProjectMatrixItemsCommentsState),
      tap(action => {
        if (action.addLoader) {
          this.store.dispatch(addLoader(action));
        }
      }),
      mergeMap(action =>
        this.projectService.getProjectMatrixItemsCommentsCounters(action.projectId).pipe(
          mergeMap(comments => [removeLoader(action), populateProjectMatrixItemsCommentsState({ states: comments })]),
          catchError(() => [removeLoader(action)]),
        ),
      ),
    );
  });

  getProjectMatrixItems = createEffect(() => {
    return this.actions.pipe(
      ofType(getProjectMatrixItems),
      tap(action => {
        if (action.addLoader) {
          this.store.dispatch(addLoader(action));
        }
      }),
      mergeMap(action =>
        this.projectService.getProjectMatrixByProjectId(action.projectId).pipe(
          mergeMap((items: IStudyMatrixItem[]) => [
            populateProjectMatrixItems({ items: this.mergeMatrixItemsByGuid(items) }),
            loadProjectMatrixItemsCommentsState({ projectId: action.projectId, addLoader: action.addLoader }),
            removeLoader(action),
          ]),
          catchError(() => [removeLoader(action)]),
        ),
      ),
    );
  });

  createProjectMatrixItem = createEffect(() => {
    return this.actions.pipe(
      ofType(createProjectMatrixItem),
      mergeMap(({ item, projectId }) =>
        this.projectService.createProjectMatrixItem(item).pipe(
          mergeMap(item => [
            addProjectMatrixItemInList({ item }),
            projectId ? loadProjectMatrixItemsCommentsState({ projectId }) : null,
            globalLoading(false),
          ]),
          catchError(() => [globalLoading(false)]),
        ),
      ),
    );
  });

  updateProjectMatrixItem = createEffect(() => {
    return this.actions.pipe(
      ofType(updateProjectMatrixItem),
      mergeMap(({ item, projectId }) =>
        this.projectService.updateProjectMatrixItem(item).pipe(
          mergeMap(item => {
            return [
              updateProjectMatrixItemInList({ item }),
              projectId ? loadProjectMatrixItemsCommentsState({ projectId }) : null,
              globalLoading(false),
            ];
          }),
          catchError(() => [globalLoading(false)]),
        ),
      ),
    );
  });

  bulkUpdateProjectMatrixItemsStatuses = createEffect(() => {
    return this.actions.pipe(
      ofType(bulkUpdateProjectMatrixItemsStatuses),
      mergeMap(({ projectId, newStatus, ids }) =>
        this.projectService.bulkUpdateProjectMatrixItemsStatues(projectId, newStatus, ids).pipe(
          mergeMap(() => [getProjectMatrixItems({ projectId }), globalLoading(false)]),
          catchError(() => [globalLoading(false)]),
        ),
      ),
    );
  });

  syncStudies = createEffect(() => {
    return this.actions.pipe(
      ofType(syncStudies),
      mergeMap(() =>
        this.projectService.syncStudies().pipe(
          mergeMap(() => [loadProjectList()]),
          catchError(() => [globalLoading(false)]),
        ),
      ),
    );
  });

  updateEntitiesStatuses = createEffect(() => {
    return this.actions.pipe(
      ofType(updateEntitiesStatuses),
      mergeMap(({ projectId, newStatus }) =>
        this.projectService.updateAllEntitiesStatuses(projectId, newStatus).pipe(
          mergeMap(() => [globalLoading(false)]),
          catchError(() => [globalLoading(false)]),
        ),
      ),
    );
  });

  publishProject = createEffect(() => {
    return this.actions.pipe(
      ofType(publishProject),
      tap(action => {
        if (action.addLoader) {
          this.store.dispatch(addLoader(action));
        }
      }),
      mergeMap(({ projectId, environment }) =>
        this.projectService.publishProject(projectId, environment).pipe(
          mergeMap(action => [
            updateProjectStatus({ projectId: projectId, newStatus: Statuses.Published }),
            removeLoader(action),
            globalLoading(false),
          ]),
          catchError(action => [globalLoading(false), removeLoader(action)]),
        ),
      ),
    );
  });
  publishAmendedProject = createEffect(() => {
    return this.actions.pipe(
      ofType(publishAmendedProject),
      tap(action => {
        if (action.addLoader) {
          this.store.dispatch(addLoader(action));
        }
      }),
      mergeMap(({ projectId, environment }) =>
        this.projectService.publishAmendedProject(projectId, environment).pipe(
          mergeMap(action => [
            updateProjectStatus({ projectId: projectId, newStatus: Statuses.Published }),
            removeLoader(action),
            globalLoading(false),
          ]),
          catchError(action => [globalLoading(false), removeLoader(action)]),
        ),
      ),
    );
  });

  private mergeMatrixItemsByGuid(items: IStudyMatrixItem[]): IStudyMatrixItem[] {
    return items.reduce((acc, item: IStudyMatrixItem) => {
      if (!item.projectMatrixGuid) {
        acc.push(item);
      } else {
        const existingItem = acc.find((x: IStudyMatrixItem) => x.projectMatrixGuid === item.projectMatrixGuid);
        if (existingItem) {
          if (item.sofaVersionId > existingItem.sofaVersionId) {
            acc[acc.indexOf(existingItem)] = item;
          }
        } else {
          acc.push(item);
        }
      }
      return acc;
    }, []);
  }
}
