<div
  class="mf-question"
  [class.button-block_parent]="!builderMode"
  [class.mf-question--builder-mode]="builderMode"
  [class.mf-question--question-group]="question.isGroup"
  [class.mf-question__pending-changes]="hasChanges && (isQuestionActive$ | async)"
  (click)="editQuestion($event, question)">
  <!-- https://pm.bgosoftware.com/browse/PHAR-466 - point 7 -->
  <h5
    class="mf-question_title"
    [class.builder-element--error]="builderMode && !question.title"
    [class.builder-element]="builderMode"
    [class.button-block_parent]="builderMode">
    @if (question.isGroup) {
      <div [ngClass]="{ 'mb-3': !question.info }">
        {{ question.label }}
      </div>
    }
    @if (
      builderMode &&
      ((isQuestionActive$ | async) || (isControlHovered$ | async).controlID) &&
      !(elementDraggedHelper$ | async).type
    ) {
      <div class="button-block_holder_question">
        @if (isDraggable) {
          <button
            class="button-block_button cursor-move"
            draggable="true"
            mat-icon-button
            pharTooltipPosition="above"
            [pharTooltip]="'Move question'"
            (dragend)="onQuestionDragEnd()"
            (dragstart)="onQuestionDragStart($event)">
            <mat-icon class="button-block_small-icon" svgIcon="handle" />
          </button>
        }

        <div class="ms-auto">
          @if (!question.isDisabled) {
            <button
              class="button-block_button"
              mat-icon-button
              pharTooltipPosition="above"
              pharNoPropagation
              [pharTooltip]="'Move Question' + (question.isGroup ? ' Group' : '')"
              (click)="moveQuestionToPage()">
              <mat-icon class="button-block_small-icon" svgIcon="swap" />
            </button>
          }

          <button
            class="button-block_button"
            mat-icon-button
            pharTooltipPosition="above"
            pharNoPropagation
            [pharTooltip]="'Duplicate Question' + (question.isGroup ? ' Group' : '')"
            (click)="duplicateQuestion()">
            <mat-icon class="button-block_small-icon" svgIcon="duplicate" />
          </button>

          <!--          <button-->
          <!--            class="button-block_button"-->
          <!--            mat-icon-button-->
          <!--            pharTooltipPosition="above"-->
          <!--            pharNoPropagation-->
          <!--            [disabled]="(canSaveQuestion$ | async) === false"-->
          <!--            [pharTooltip]="'Save question'"-->
          <!--            (click)="saveQuestion($event, question)"-->
          <!--          >-->
          <!--            <mat-icon class="button-block_small-icon" svgIcon="save"></mat-icon>-->
          <!--          </button>-->

          <button
            class="button-block_button"
            mat-icon-button
            pharTooltipPosition="above"
            pharNoPropagation
            [pharTooltip]="'Remove question'"
            (click)="removeQuestion($event, question)">
            <mat-icon class="button-block_small-icon" svgIcon="trash" />
          </button>
        </div>
      </div>
    }
  </h5>

  @if (builderMode && !question.title) {
    <mat-error class="mf-question_title_error-required"> Question title is required. </mat-error>
  } @else {
    <div class="d-flex flex-wrap">
      @if (question?.info) {
        <mat-hint class="me-auto custom-hint mb-3">{{ question.info }}</mat-hint>
      }
    </div>
  }

  <phar-question-drop-zone
    pharNoPropagation
    [builderMode]="builderMode"
    [questionControls]="controls"
    [question]="question"
    [page]="page"
    [allowMultipleControls]="(isQuestionActive$ | async) && question.isGroup"
    [isCommentsVisible]="isCommentsVisible"
    [isVerticalLayout]="isVerticalLayout"
    [softDeleteQuestion]="form().isAmended"
    (controlsUpdated)="editControls($event)"
    (selectEditMode)="setSelectEditMode($event)" />
</div>
